@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }
} */

::selection {
  color: none;
  background: none;
}
/* For Mozilla Firefox */
::-moz-selection {
  color: none;
  background: none;
}

@font-face {
  font-family: 'ContaxProBold';
  src: url('fonts/ContaxPro75Bold.otf') format('opentype');
}
@font-face {
  font-family: 'ContaxPro';
  src: url('fonts/ContaxPro55Rm.otf') format('opentype');
}

* {
  user-select: none;
}

html {
  font-family: 'ContaxProBold', 'ui-sans-serif', 'system-ui', sans-serif;
}

p {
  font-family: 'ContaxPro', 'ui-sans-serif', 'system-ui', sans-serif;
}

.app-container {
  overflow-y: auto;
}

:root {
  --animation-speed: 1000ms;
  --animation-speed-fast: 250ms;
  --default-cell-bg-color: theme('colors.cell-no-status');
  --default-cell-border-color: theme('colors.no-status-cell-border');
  --default-cell-text-color: theme('colors.cell-no-status-text');
  --no-status-cell-text-color: theme('colors.cell-no-status-text');
  --no-status-cell-border: theme('colors.no-status-cell-border');
  --absent-cell-bg-color: theme('colors.cell-absent');
  --absent-cell-text-color: theme('colors.cell-absent-text');
  --correct-cell-bg-color: theme('colors.cell-correct');
  --correct-cell-text-color: theme('colors.white');
  --present-cell-bg-color: theme('colors.cell-present');
  --present-cell-text-color: theme('colors.white');
}

html {
  background-color: theme('colors.main-bg');
}

html.dark {
  background-color: theme('colors.dark-main-bg');
}

.dark {
  --default-cell-bg-color: theme('colors.dark-cell-no-status');
  --default-cell-border-color: theme('colors.dark-no-status-cell-border');
  --default-cell-text-color: theme('colors.dark-cell-no-status-text');
  --no-status-cell-text-color: theme('colors.dark-cell-no-status-text');
  --no-status-cell-border: theme('colors.dark-no-status-cell-border');
  --absent-cell-bg-color: theme('colors.dark-cell-no-status');
}

.cell-fill-animation {
  animation: onTypeCell linear;
  animation-duration: 0.35s;
}

.cell-reveal {
  animation-duration: 0.35s;
  animation-timing-function: linear;
  /* animation-iteration-count: infinite; */
  animation-direction: alternate;
  animation-fill-mode: backwards;
}

.cell-reveal.absent {
  animation-name: revealAbsentCharCell;
}

.cell-reveal.correct {
  animation-name: revealCorrectCharCell;
}

.cell-reveal.present {
  animation-name: revealPresentCharCell;
}

.cell-reveal > .letter-container {
  animation: offsetLetterFlip 0.35s linear;
  animation-fill-mode: backwards;
}

svg.cursor-pointer {
  transition: all var(--animation-speed-fast);
}

svg.cursor-pointer:hover {
  transform: scale(1.2);
}

.jiggle {
  animation: jiggle linear;
  animation-duration: var(--animation-speed-fast);
}

.navbar {
  margin-bottom: 2%;
}

.navbar-content {
  display: flex;
  height: 3rem;
  align-items: center;
  justify-content: space-between;
}

@keyframes revealAbsentCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    color: var(--no-status-cell-text-color);
    border-color: var(--no-status-cell-border);
    border-width: 1px;
  }
  50% {
    background-color: var(--default-cell-bg-color);
    color: var(--no-status-cell-text-color);
    border-color: var(--no-status-cell-border);
  }
  50.1% {
    background-color: var(--absent-cell-bg-color);
    border-color: var(--absent-cell-bg-color);
    color: var(--absent-cell-text-color);
  }
  100% {
    transform: rotateX(180deg);
    border-color: var(--absent-cell-bg-color);
    color: var(--absent-cell-text-color);
    border-width: 0;
  }
}

@keyframes revealCorrectCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    color: var(--no-status-cell-text-color);
    border-color: var(--no-status-cell-border);
    border-width: 1px;
  }
  50% {
    background-color: var(--default-cell-bg-color);
    color: var(--no-status-cell-text-color);
    border-color: var(--no-status-cell-border);
  }
  50.1% {
    background-color: var(--correct-cell-bg-color);
    border-color: var(--correct-cell-bg-color);
    color: var(--correct-cell-text-color);
  }
  100% {
    transform: rotateX(180deg);
    border-color: var(--correct-cell-bg-color);
    color: var(--correct-cell-text-color);
    border-width: 0;
  }
}

@keyframes revealPresentCharCell {
  0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    color: var(--no-status-cell-text-color);
    border-color: var(--no-status-cell-border);
    border-width: 1px;
  }
  50% {
    background-color: var(--default-cell-bg-color);
    color: var(--no-status-cell-text-color);
    border-color: var(--no-status-cell-border);
  }
  50.1% {
    background-color: var(--present-cell-bg-color);
    border-color: var(--present-cell-bg-color);
    color: var(--present-cell-text-color);
  }
  100% {
    transform: rotateX(180deg);
    border-color: var(--present-cell-bg-color);
    color: var(--present-cell-text-color);
    border-width: 0;
  }
}

/* Additional animation on the child div to avoid letters turning upside down/snapping back to upright visual glitch */
@keyframes offsetLetterFlip {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes onTypeCell {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes jiggle {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-0.5rem, 0);
  }
  50% {
    transform: translate(0.5rem, 0);
  }
  75% {
    transform: translate(-0.5rem, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.grid-circle {
  border-radius: 50%;
}

.cell-row {
  background-color: #000000;
  border-radius: 50%;
}

.bg-default {
  background-color: #3f4444;
}

.bg-cell-green {
  background-color: #00a582;
}

.bg-cell-yellow {
  background-color: #f5be27;
}

.rounded-4xl {
  border-radius: 40px;
}

.font-bold {
  font-family: 'ContaxProBold', 'ui-sans-serif', 'system-ui', sans-serif;
}

.font-normal {
  font-family: 'ContaxPro', 'ui-sans-serif', 'system-ui', sans-serif;
}

.bg-green {
  background-color: #00a582;
}

.toggle-button {
  line-height: 0.7rem;
}

.toggle-button.bg-white {
  background-color: white;
}

.play-icon-animating {
  transform: rotate(180deg);
}

.play-icon-animating .play-icon-bar-left {
  display: block;
  position: absolute;
  animation: 1s ease-out infinite animateBars;
}

.play-icon-animating .play-icon-bar-middle {
  display: block;
  position: absolute;
  animation: 0.6s linear infinite animateBars;
}

.play-icon-animating .play-icon-bar-right {
  display: block;
  position: absolute;
  animation: 0.52s ease-in infinite animateBars;
}

@keyframes animateBars {
  0% {
    height: 0%;
  }
  25% {
    height: 50%;
  }
  60% {
    height: 35%;
  }
  80% {
    height: 38%;
  }
}

@keyframes pulse {
  0% {
    r: 20px;
    opacity: 0.8;
  }
  100% {
    r: 40px;
    opacity: 0;
  }
}

.animate-pulse {
  animation: pulse 1.6s ease-out infinite;
}

.animate-glow {
  animation: glow 0.8s ease-in-out infinite;
  animation-direction: alternate;
}

@keyframes glow {
  0% {
    opacity: 0.8;
    transform: scale(0.98);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-gentle-ping {
  animation: gentlePing 0.8s ease-in-out infinite;
  animation-direction: alternate;
}

@keyframes gentlePing {
  0% {
    opacity: 0.8;
    transform: scale(0.94);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-less-gentle-ping {
  animation: lessGentlePing 0.8s ease-in-out infinite;
  animation-direction: alternate;
}

@keyframes lessGentlePing {
  0% {
    opacity: 0.8;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1.1);
  }
}

.animation-pop {
  animation: singlePop 3s linear;
  opacity: 1;
  transform: scale(1);
}

@keyframes singlePop {
  0% {
    opacity: 0.5;
    transform: scale(1.1);
  }
  10% {
    opacity: 1;
    transform: scale(1);
  }
  80% {
    opacity: 0.8;
    transform: scale(1);
  }
}

.lumi-promo {
  background-image: url(../src/components/images/lumi-banner.png);
  background-position-x: 60%;
  background-size: cover;
  background-repeat: no-repeat;
}

.keyboard {
  margin-bottom: env(safe-area-inset-bottom, 24px);
}

@media (max-width: 640px) {
  .info-modal .w-10 {
    width: 2rem;
  }
  .info-modal .h-10 {
    height: 2rem;
  }
}

.touch-highlight-off {
  -webkit-tap-highlight-color: transparent;
}

/* GIANT STYLES */
@media (min-width: 1700px) and (min-height: 1020px) {
  .grid-keys-wrapper {
    flex-grow: 0;
    transform: scale(1.3);
    transform-origin: top;
    margin-top: 30px;
  }

  .modal-wrapper {
    transform: scale(1.3);
  }
}
